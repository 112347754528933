<template>
	<div class="outside">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item>角色权限</el-breadcrumb-item>
			<el-breadcrumb-item>编辑角色</el-breadcrumb-item>
		</el-breadcrumb>
		<el-form style="margin-top: 20px;" :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
		 label-width="80px">
			<el-form-item label="角色名称" prop="name">
				<el-input v-model="dataForm.name" style="width: 300px;" placeholder="角色名称"></el-input>
			</el-form-item>
			<el-form-item label="备注" class="justify" prop="des">
				<el-input v-model="dataForm.des" style="width: 300px;" placeholder="备注"></el-input>
			</el-form-item>
			<el-form-item size="mini" label="菜单权限">
				<el-tree :data="menuList" :props="menuListTreeProps" node-key="id" ref="menuListTree" :default-checked-keys="defaultChecked"
				 :default-expand-all="true"  show-checkbox>
				</el-tree>
			</el-form-item>
		</el-form>
		<span slot="footer" style="margin-left: 100px;margin-top: 30px;" class="dialog-footer">
			<el-button @click="tabsCloseCurrentHandle()">取消</el-button>
			<el-button type="primary" @click="dataFormSubmit()">确定</el-button>
		</span>
	</div>
</template>
<style>
</style>
<script>
	import Qs from 'qs'
	import {
		treeDataTranslate
	} from '@/utils'
	export default {
		data() {
			return {
				visible: false,
				menuList: [],
				dataForm:{
					name:'',
					des:''
				},
				menuListTreeProps: {
					label: 'name',
					children: 'children'
				},
				mostChildrenArr:[],
				defaultChecked: [],
				defaultChecked2: [],
				dataRule: {
					name: [{
						required: true,
						message: '角色名称不能为空',
						trigger: 'blur'
					}],
					des: [{
						required: true,
						message: '备注不能为空',
						trigger: 'blur'
					}]
				},


			}
		},
		 computed: {
		  mainTabs: {
		    get () { return this.$store.state.common.mainTabs },
		    set (val) { this.$store.commit('common/updateMainTabs', val) }
		  },
		  mainTabsActiveName: {
		    get () { return this.$store.state.common.mainTabsActiveName },
		    set (val) { this.$store.commit('common/updateMainTabsActiveName', val) }
		  },
		  menuActiveName: {
		    get() {return this.$store.state.common.menuActiveName },
		    set(val) {this.$store.commit('common/updateMenuActiveName', val)}
		  },
		},
		mounted() {
			this.init();
		},
		methods: {

		async	init() {
				
				// 		 this.menuList = JSON.parse(sessionStorage.getItem('menuList'))
				// this.getDefaultChecked(	 this.menuList )
				// 	const { data } = await this.$httpAes({
				// 	  url: _this.$httpAes.adornUrl("/permission/userList" ),
				// 	  method: "post",
				// 	  data: {intParam1:1
				// 	  },
				// 	  contentType:'json',
				// 	  headers: {
				// 	  	'Content-Type': 'application/json',
					     				
				// 	  },
					   
				// 	});
					
				// 	if (data.status) {
				// 	  _this.menuList = data.data;
				
				// 	}
				this.dataForm = JSON.parse(sessionStorage.getItem('roleInfo'))
				this.menuList = JSON.parse(sessionStorage.getItem('allMenu'))
				console.log('12321321321')
				this.$httpAes({
					url: this.$httpAes.adornUrl('permission/userList'),
					method: 'post',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
				
			'token':this.$cookie.get('token'),
					},
				}).then(({
					data
				}) => {
					var myData = data.list
					this.menuList =  data.list
					this.getDefaultChecked(data.list)
					this.$httpAes({
							url: this.$httpAes.adornUrl('role/info?roleId='+this.dataForm.id),
							method: 'get',
							headers: {
								'Content-Type': 'application/x-www-form-urlencoded',
						
					'token':this.$cookie.get('token'),
							},
						}).then(({
							data
						}) => {
							for(let i=0;i<data.data.role.permissionList.length;i++){
								for(let j=0;j<this.mostChildrenArr.length;j++){
									if(data.data.role.permissionList[i]==this.mostChildrenArr[j]){
										this.defaultChecked.push(data.data.role.permissionList[i])
									}
								}
							}
							console.log(this.defaultChecked)
							
							this.$refs.menuListTree.setCheckedKeys(this.defaultChecked)
					
						})
				})
			},
			getDefaultChecked(arr){
				for(let i=0;i<arr.length;i++){
					if(arr[i].children==null||arr[i].children.length==0){
						this.mostChildrenArr.push(arr[i].id)
					}else{
						this.getDefaultChecked(arr[i].children)
					}
				}
				
			},
			 removeTabHandle (tabName) {
			  this.mainTabs = this.mainTabs.filter(item => item.name !== tabName)
			  if (this.mainTabs.length >= 1) {
			    // 当前选中tab被删除
			    if (tabName === this.mainTabsActiveName) {
			      var tab = this.mainTabs[this.mainTabs.length - 1]
			      this.$router.push({ name: tab.name, query: tab.query, params: tab.params }, () => {
			        this.mainTabsActiveName = this.$route.name
			      })
			    }
			  } else {
			    this.menuActiveName = ''
			    this.$router.push({ name: 'home' })
			  }
			},
			// tabs, 关闭当前
			tabsCloseCurrentHandle () {
			  this.removeTabHandle(this.mainTabsActiveName)
			},
			// 表单提交
			dataFormSubmit() {
								var menuId = ""
								let dataTree2 = this.$refs.menuListTree.getCheckedNodes()   
								 let dataTree3 = this.$refs.menuListTree.getHalfCheckedNodes()  
								 let menuData=dataTree2.concat(dataTree3);
								let menuIdData = []
								let flag = []
								for(var i=0;i<menuData.length;i++)
								{
									let myFlag = false
									menuIdData.push(menuData[i].id)
									for(var j=0;j<dataTree2.length;j++){
										if(dataTree2[j].id==menuData[i].id){
											myFlag=true
										}
									}
									if(myFlag){
										flag.push('1')
									}else{
										flag.push('0')
									}
								}
								menuId = menuIdData.join(',')

				let Role = {
					"id": this.dataForm.id,
					"name": this.dataForm.name,
					"des": this.dataForm.des,
					permissionIds: menuId
				}
				this.$refs['dataForm'].validate((valid) => {
					if (valid) {
						if (this.$refs.menuListTree.getCheckedNodes().length == 0) {
							this.$message({
								message: '请选择菜单权限',
								type: 'error'
							});
							return
						} 
						this.$httpAes({
							url: this.$httpAes.adornUrl('role/update'),
							method: 'post',
							headers: {
								'Content-Type': 'application/x-www-form-urlencoded',
				
			'token':this.$cookie.get('token'),
							},
							data: Qs.stringify(Role)
						}).then(({
							data
						}) => {
							if (data && data.status) {
						
							this.$message({
								message: '操作成功',
								type: 'success',
								duration: 1500,
								onClose: () => {
								this.tabsCloseCurrentHandle()
								}
							})
						} else {
							this.$message.error(data.msg)
						}
						})
					}
				})
			}
		}
	}

	function menuTreeDataTranslate(data) {
		// 删除 所有 children,以防止多次调用
		data.forEach(function(item) {
			delete item.list;
		});

		// 将数据存储为 以 id 为 KEY 的 map 索引数据列
		var map = {};
		data.forEach(function(item) {
			map[item.code] = item;
		});
		var val = [];
		data.forEach(function(item) {
			// 以当前遍历项，的pid,去map对象中找到索引的id
			var parent = map[item.pcode];
			// 好绕啊，如果找到索引，那么说明此项不在顶级当中,那么需要把此项添加到，他对应的父级中
			if (parent) {
				(parent.list || (parent.list = [])).push(item);
			} else {
				//如果没有在map中找到对应的索引ID,那么直接把 当前的item添加到 val结果集中，作为顶级
				val.push(item);
			}
		});
		return val;

	}
</script>
